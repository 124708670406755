import React from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../components/Link';
import Layout from '../components/Layout';
// import search from '../img/search.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="not_found is-flex is-align-items-center is-justify-content-center py-6">
        <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center py-6">
          <h1 className="has-text-weight-bold has-text-link is-uppercase">
            404
          </h1>
          <h2 className="has-text-link has-text-weight-bold is-uppercase mb-4 mb-2">
            {t('404.request_page_not_found')}
          </h2>
          <p className="mb-5">
            {t('404.cant_find')}
            <Link to="/">{t('404.our_homepage')}</Link>
          </p>
          {/*<form*/}
          {/*  className="search"*/}
          {/*  action=""*/}
          {/*  method="get"*/}
          {/*>*/}
          {/*  <div className="field">*/}
          {/*    <p className="control has-icons-right">*/}
          {/*      <input className="input" type="search" placeholder={t('404.search_enter')} />*/}
          {/*      <span className="icon is-small is-right">*/}
          {/*        <img*/}
          {/*          className="has-text-grey-lighter"*/}
          {/*          src={search}*/}
          {/*          alt="search"*/}
          {/*        />*/}
          {/*      </span>*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</form>*/}
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
